<template>
  <div
    v-bind:style="{ right: toggle_right_Sidebar + 'rem' }"
    class="rightSideBarNew right_bg close-right-sidebar-16rem z-40 md:right-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative"
  >
    <div class="settingsHeader flex justify-between items-center p-2">
      <p class="settingsHeading">ALERTS & NOTIFICATIONS</p>
      <svg
        class="cursor-pointer"
        @click="closeNavigation"
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
      >
        <path
          d="M5.93996 5.00004L8.80662 2.14004C8.93216 2.01451 9.00269 1.84424 9.00269 1.66671C9.00269 1.48917 8.93216 1.31891 8.80662 1.19338C8.68109 1.06784 8.51083 0.997314 8.33329 0.997314C8.15576 0.997314 7.9855 1.06784 7.85996 1.19338L4.99996 4.06004L2.13996 1.19338C2.01442 1.06784 1.84416 0.997314 1.66663 0.997314C1.48909 0.997314 1.31883 1.06784 1.19329 1.19338C1.06776 1.31891 0.997231 1.48917 0.997231 1.66671C0.997231 1.84424 1.06776 2.01451 1.19329 2.14004L4.05996 5.00004L1.19329 7.86004C1.13081 7.92202 1.08121 7.99575 1.04737 8.07699C1.01352 8.15823 0.996094 8.24537 0.996094 8.33337C0.996094 8.42138 1.01352 8.50852 1.04737 8.58976C1.08121 8.671 1.13081 8.74473 1.19329 8.80671C1.25527 8.86919 1.329 8.91879 1.41024 8.95264C1.49148 8.98648 1.57862 9.00391 1.66663 9.00391C1.75463 9.00391 1.84177 8.98648 1.92301 8.95264C2.00425 8.91879 2.07798 8.86919 2.13996 8.80671L4.99996 5.94004L7.85996 8.80671C7.92193 8.86919 7.99567 8.91879 8.07691 8.95264C8.15815 8.98648 8.24528 9.00391 8.33329 9.00391C8.4213 9.00391 8.50844 8.98648 8.58968 8.95264C8.67092 8.91879 8.74465 8.86919 8.80662 8.80671C8.86911 8.74473 8.91871 8.671 8.95255 8.58976C8.9864 8.50852 9.00382 8.42138 9.00382 8.33337C9.00382 8.24537 8.9864 8.15823 8.95255 8.07699C8.91871 7.99575 8.86911 7.92202 8.80662 7.86004L5.93996 5.00004Z"
          fill="#B4B5BA"
        />
      </svg>
    </div>
    <div class="bodyOfrightSidebar overflow-y-auto">
      <div class="mainOrgHie mb-1">
        <div
          @click="onSelectOfrightBarClicked(1)"
          class="headerLabel flex justify-between items-center p-2"
        >
          <p>USER INFO</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            :class="{ 'rotate-up': rightBarSelectclicked.includes(1) }"
          >
            <path
              d="M10.9999 2.59L6.70995 6.83C6.61699 6.92372 6.50638 6.99812 6.38453 7.04889C6.26267 7.09965 6.13196 7.12579 5.99995 7.12579C5.86794 7.12579 5.73723 7.09965 5.61537 7.04889C5.49351 6.99812 5.38291 6.92372 5.28995 6.83L1.04995 2.59C0.95622 2.49703 0.881826 2.38643 0.831057 2.26457C0.780288 2.14271 0.75415 2.01201 0.75415 1.88C0.75415 1.74798 0.780288 1.61728 0.831057 1.49542C0.881826 1.37356 0.95622 1.26296 1.04995 1.17C1.23731 0.983744 1.49076 0.879203 1.75495 0.879203C2.01913 0.879203 2.27259 0.983744 2.45995 1.17L5.99995 4.71L9.53995 1.17C9.72621 0.985251 9.97761 0.8811 10.2399 0.879995C10.3716 0.879234 10.502 0.904459 10.6239 0.954224C10.7457 1.00399 10.8565 1.07731 10.9499 1.17C11.047 1.25961 11.1254 1.36758 11.1805 1.48765C11.2356 1.60773 11.2664 1.73754 11.271 1.86957C11.2757 2.00161 11.2541 2.13325 11.2076 2.25691C11.161 2.38056 11.0905 2.49377 10.9999 2.59Z"
              fill="#727272"
            />
          </svg>
        </div>
        <div
          v-if="rightBarSelectclicked.includes(1)"
          class="bodyOf1 transition ease-in-out duration-500 px-2"
        >
          <div class="px-2">
            <label for="displayName" class="selectLabel"
              >NAME : {{ organizationHierarchy.displayName }}</label
            >
            <!-- <select
                class="w-full selectele"
                v-model="organizationHierarchy.displayName"
              >
                <option selected :value=organizationHierarchy.displayName>{{organizationHierarchy.displayName}}</option>
              </select> -->
          </div>
          <div class="px-2">
            <label for="" class="selectLabel"
              >ROLE : {{ organizationHierarchy.jobRole }}</label
            >
            <!-- <select
                class="w-full selectele"
                v-model="organizationHierarchy.jobRole"
              >
                <option selected :value=organizationHierarchy.jobRole>{{ organizationHierarchy.jobRole }}</option>
              </select> -->
          </div>
          <!-- <div class="px-2">
              <label for="whichSolutin" class="selectLabel"
                >APOLLODART SOLUCTIONS</label
              >
              <select
                class="w-full selectele"
                v-model="organizationHierarchy.apolloDartSolutionType"
              >
                <option selected value="displayName">DIS</option>
              </select>
            </div> -->
          <div class="px-2">
            <label for="typeOfAlert" class="selectLabel">ALERT MODE</label>
            <div class="flex w-full">
              <div class="selectLabel flex items-center">
                <input
                  class="mr-1"
                  id="sms-mode"
                  type="radio"
                  name="alertMode"
                  value="sms"
                  v-model="organizationHierarchy.alertMode"
                />
                <label class="mr-4" for="sms-mode">sms</label>
              </div>
              <div class="selectLabel flex items-center">
                <input
                  class="mr-1"
                  id="email-mode"
                  type="radio"
                  value="email"
                  name="alertMode"
                  v-model="organizationHierarchy.alertMode"
                />
                <label for="email-mode">Email</label>
              </div>
            </div>
            <div class="flex items-center justify-between mt-3 mb-3">
              <p class="selectLabel">DO NOT DISTURB </p>
              <label class="switch">
                <input
                  type="checkbox"
                  :checked="alertInfo.communicationRequired"
                  v-model="alertInfo.communicationRequired"
                 
                />
                <span class="slider round"></span>
              </label>
            </div>
            <!-- <select
                class="w-full selectele"
                v-model="organizationHierarchy.alertMode"
              >
                <option selected value="sms">SMS</option>
                <option selected value="email">EMAIL</option>
              </select> -->
          </div>
        </div>
      </div>
      <div v-if="!alertInfo.communicationRequired" class="mainOfAlert mb-1">
        <div
          @click="onSelectOfrightBarClicked(2)"
          class="headerLabel flex justify-between items-center p-2"
        >
          <p>ALERT</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            :class="{ 'rotate-up': rightBarSelectclicked.includes(2) }"
          >
            <path
              d="M10.9999 2.59L6.70995 6.83C6.61699 6.92372 6.50638 6.99812 6.38453 7.04889C6.26267 7.09965 6.13196 7.12579 5.99995 7.12579C5.86794 7.12579 5.73723 7.09965 5.61537 7.04889C5.49351 6.99812 5.38291 6.92372 5.28995 6.83L1.04995 2.59C0.95622 2.49703 0.881826 2.38643 0.831057 2.26457C0.780288 2.14271 0.75415 2.01201 0.75415 1.88C0.75415 1.74798 0.780288 1.61728 0.831057 1.49542C0.881826 1.37356 0.95622 1.26296 1.04995 1.17C1.23731 0.983744 1.49076 0.879203 1.75495 0.879203C2.01913 0.879203 2.27259 0.983744 2.45995 1.17L5.99995 4.71L9.53995 1.17C9.72621 0.985251 9.97761 0.8811 10.2399 0.879995C10.3716 0.879234 10.502 0.904459 10.6239 0.954224C10.7457 1.00399 10.8565 1.07731 10.9499 1.17C11.047 1.25961 11.1254 1.36758 11.1805 1.48765C11.2356 1.60773 11.2664 1.73754 11.271 1.86957C11.2757 2.00161 11.2541 2.13325 11.2076 2.25691C11.161 2.38056 11.0905 2.49377 10.9999 2.59Z"
              fill="#727272"
            />
          </svg>
        </div>
        <div v-if="rightBarSelectclicked.includes(2)" class="bodyOf1 px-1">
          <div class="px-2">
            <div class="headerLabel mt-2 alertPickerHeading">
              <label for="displayName" class="selectLabel">ALERT PICKER</label>
            </div>
            <!-- <select
                class="w-full selectele" multiple
              >
                <option selected value="all">All</option>
                <option  value="PIVOTAL_ALERTS">Pivotal Alerts</option>
                <option  value="CRUCIAL_ALERTS">Crucial Alerts</option>
                <option  value="CRITICAL_CONDITION_ALERTS">
                  Critical Condition Alerts
                </option>
              </select> -->
            <div
              v-if="!(isPoChecked || isCCChecked || isCchecked)"
              class="px-2 flex items-center justify-between mt-2"
            >
              <label for="automatedDefault" class="selectLabel cursor-pointer"
                >All</label
              >
              <input
                type="checkbox"
                id="automatedDefault"
                @click="onAlertPickerClicked('all')"
                v-model="isAllChekced"
                :disabled="isPoChecked || isCCChecked || isCchecked"
              />
            </div>
            <div
              v-if="!isAllChekced"
              class="px-2 flex items-center justify-between mt-2"
            >
              <label for="PAlerts" class="selectLabel cursor-pointer"
                >Pivotal Alerts</label
              >
              <input
                type="checkbox"
                id="PAlets"
                @click="onAlertPickerClicked('PIVOTAL_ALERTS')"
                value="PIVOTAL_ALERTS"
                v-model="isPoChecked"
                :disabled="isAllChekced"
              />
            </div>
            <div
              v-if="!isAllChekced"
              class="px-2 flex items-center justify-between mt-2"
            >
              <label for="CcAlerts" class="selectLabel cursor-pointer"
                >Critical Conditon Alerts
              </label>
              <input
                type="checkbox"
                id="CcAlerts"
                @click="onAlertPickerClicked('CRITICAL_CONDITION_ALERTS')"
                v-model="isCCChecked"
                :disabled="isAllChekced"
              />
            </div>
            <div
              v-if="!isAllChekced"
              class="px-2 flex items-center justify-between mt-2"
            >
              <label for="crucialAlertsInput" class="selectLabel cursor-pointer"
                >Crucial Alerts</label
              >
              <input
                type="checkbox"
                id="crucialAlertsInput"
                @click="onAlertPickerClicked('CRUCIAL_ALERTS')"
                value="CRUCIAL_ALERTS"
                v-model="isCchecked"
                :disabled="isAllChekced"
              />
            </div>
          </div>
          <!-- <div class="px-2">
              <label for="" class="selectLabel">Alert Preference</label>
              <select class="w-full selectele">
                <option selected value="displayName">SMS</option>
                <option selected value="displayName">EMAIL</option>
              </select>
            </div> -->
          <div class="px-2">
            <label for="whichSolutin" class="selectLabel"
              >ALERT FREQUENCY</label
            >
            <!-- <select
                class="w-full selectele"
                v-model="alertInfo.alertFrequency"
              >
                <option selected value="3">3</option>
              </select> -->
            <input
              type="number"
              class="selectele w-full"
              min="0"
              onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
              v-model="alertInfo.alertFrequency"
            />
          </div>
          <div class="px-2">
            
            <div class="">
              <label for="" class="selectLabel">DURATION (Minutes)</label>
              <!-- <Datepicker
                  :dark="darkDark === true"
                  palceholder="MM/DD/YYY"
                  v-model="formDate"
                /> -->
              <input
                type="number"
                class="selectele w-full"
                min="0"
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                v-model="durationBwAlerts"
              />
            </div>

            <!-- <div class="px-2">
                <label for="" class="selectLabel">End Time</label>
                <Datepicker v-model="toDate" :dark="darkDark === true" />
              </div> -->
          </div>
          <div class="px-2" v-if="organizationHierarchy.alertMode && organizationHierarchy.alertMode == 'sms' && 
            organizationHierarchy.jobRole && organizationHierarchy.jobRole.replace(/\s/g,'').toLowerCase() == 'admin' 
          ">
            <label for="whichSolutin" class="selectLabel"
              >CUSTOM MOBILE NUMBER</label
            >
            <multiSelect
              :reqOptions="customMobileNumbers"
              :selectedOptions="selected_cust_mobile_nbs"
              @selectOption="selectOption"
            />
          </div>
          <!-- <div class="px-2 flex items-center justify-between mt-3 mb-3">
            <p class="selectLabel">DO NOT DISTURB</p>
            <label class="switch">
              <input
                type="checkbox"
                :checked="alertInfo.communicationRequired"
                v-model="alertInfo.communicationRequired"
                @click="toggleClicked()"
              />
              <span class="slider round"></span>
            </label>
          </div> -->
        </div>
      </div>
      <!-- <div class="mainOfAlertSeverity mb-1">
          <div
            @click="onSelectOfrightBarClicked(3)"
            class="headerLabel flex justify-between items-center p-2"
          >
            <p>Alert Severity</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
            >
              <path
                d="M10.9999 2.59L6.70995 6.83C6.61699 6.92372 6.50638 6.99812 6.38453 7.04889C6.26267 7.09965 6.13196 7.12579 5.99995 7.12579C5.86794 7.12579 5.73723 7.09965 5.61537 7.04889C5.49351 6.99812 5.38291 6.92372 5.28995 6.83L1.04995 2.59C0.95622 2.49703 0.881826 2.38643 0.831057 2.26457C0.780288 2.14271 0.75415 2.01201 0.75415 1.88C0.75415 1.74798 0.780288 1.61728 0.831057 1.49542C0.881826 1.37356 0.95622 1.26296 1.04995 1.17C1.23731 0.983744 1.49076 0.879203 1.75495 0.879203C2.01913 0.879203 2.27259 0.983744 2.45995 1.17L5.99995 4.71L9.53995 1.17C9.72621 0.985251 9.97761 0.8811 10.2399 0.879995C10.3716 0.879234 10.502 0.904459 10.6239 0.954224C10.7457 1.00399 10.8565 1.07731 10.9499 1.17C11.047 1.25961 11.1254 1.36758 11.1805 1.48765C11.2356 1.60773 11.2664 1.73754 11.271 1.86957C11.2757 2.00161 11.2541 2.13325 11.2076 2.25691C11.161 2.38056 11.0905 2.49377 10.9999 2.59Z"
                fill="#727272"
              />
            </svg>
          </div>
          <div v-if="rightBarSelectclicked.includes(3)" class="bodyOf1 px-2">
            <div class="px-2 flex items-center justify-between mt-2">
              <label for="automatedDefault" class="selectLabel cursor-pointer"
                >All</label
              >
              <input
                type="checkbox"
                id="automatedDefault"
                @click="onAlertSeverityClicked('all')"
              />
            </div>
            <div  class="px-2 flex items-center justify-between mt-2">
              <label for="PAlerts" class="selectLabel cursor-pointer"
                >Pivotal Alerts</label
              >
              <input
                type="checkbox"
                id="PAlets"
                @click="onAlertSeverityClicked('PIVOTAL_ALERTS')"
              />
            </div>
            <div class="px-2 flex items-center justify-between mt-2">
              <label for="CcAlerts" class="selectLabel cursor-pointer"
                >Critical Conditon Alerts
              </label>
              <input
                type="checkbox"
                id="CcAlerts"
                @click="onAlertSeverityClicked('CRITICAL_CONDITION_ALERTS')"
              />
            </div>
            <div class="px-2 flex items-center justify-between mt-2">
              <label for="crucialAlertsInput" class="selectLabel cursor-pointer"
                >Crucial Alerts</label
              >
              <input
                type="checkbox"
                id="crucialAlertsInput"
                @click="onAlertSeverityClicked('CRUCIAL_ALERTS')"
              />
            </div>
          </div>
        </div> -->
      <div class="saveBtnDiv text-center mt-2">
        <button @click="onSaveClicked" class="saveBtn rounded">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from "../../api/services";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import multiSelect from "../Widgets/Alerts/multiSelect.vue";
// import axios from 'axios';
export default {
  components: {
    Datepicker,
    multiSelect,
  },
  props: {
    selectedWell: { type: String, defalut: "" },
    selectedWellBore: { type: String, defalut: "" },
    displayId: { type: String },
    toggle_right_Sidebar: {
      type: String,
      default: "",
    },
    toggle_right_margin: {
      type: String,
      default: "",
    },
    id: "",
    properties: {},
    closeNavigation: Function,
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  watch:{
    'organizationHierarchy.alertMode' : {
      handler : function(newVal){
        console.log("called in alert watcher::::::::")
        this.bindingTheAlreadyHavingDataToPropertyBar(newVal)
      },
      deep:true
    }
  },
  data() {
    return {
      durationBwAlerts: null,
      isPoChecked: false,
      isCCChecked: false,
      isCchecked: false,
      isAllChekced: false,
      isSeveariyClicked: false,
      storingObj: {},
      userId: null,
      entityId: null,
      toDate: null,
      formDate: null,
      pivotalAlerts: [],
      crucialAlerts: [],
      criticalAlerts: [],
      rightBarSelectclicked: [1],
      isWidgetDbclicked: false,
      showRightSideBar: false,
      alertScreenWidht: "",
      alertScreenHeight: "",
      showingAlertScreen: false,
      alerts: [],
      whichWidgetsAreShown: [],
      mapOfWellNameAndId: [],
      selectedWel: this.selectedWell,
      organizationHierarchy: {
        displayName: null,
        jobRole: null,
        apolloDartSolutionType: null,
        alertMode: null,
      },
      alertInfo: {
        alertPicker: null,
        alertFrequency: null,
        alertDuration: null,
        communicationRequired: true,
        communicationtype: null,
      },
      alertSeverity: null,
      aletPicker: null,
      alertPreference: null,
      alertfrequency: null,
      alertDuration: null,
      displayId: this.displayId,
      wellId: null,
      wellName: null,
      wellBoreName: null,
      customMobileNumbers: [],
      selected_cust_mobile_nbs: [],
    };
  },
  methods: {
    selectOption(item){
      let hadRecord = this.selected_cust_mobile_nbs.findIndex(
        (each) => each == item
      );
      if (hadRecord >= 0) {
        this.selected_cust_mobile_nbs.splice(hadRecord, 1);
      } else {
        this.selected_cust_mobile_nbs.push(item)
      }
    },
    async getting_wellwise_custom_mbl_numbers(){
      let dtls = this.$store.state.disp.displays[this.displayId];
      let obj = {
        wellId: dtls.wellId,
        wellBoreName: dtls.wellboreId,
      };
      // let obj = {
      //     "wellId":"PNRG_Main_W_45",
      //     "wellBoreName":"HEMPHILL # 1 H"
      // };
      // let response = await axios.post('http://10.10.50.13:8081/smscommunicationconfiguration/search',
      //   obj
      // );

      // response:
      //     [
      //   {
      //     "smsCommunicationConfigurationId": 1,
      //     "wellId": "PNRG_Main_W_45",
      //     "wellBoreName": "HEMPHILL # 1 H",
      //     "entityId": 10,
      //     "mobileNumbers": [
      //       "+1-8765907867",
      //       "+1-8765907868"
      //     ],
      //     "createdBy": "aslam.s@apollodart.com",
      //     "createdAt": 1710757609486
      //   }
      // // ]

      // this.customMobileNumbers = ["+1-8765907867", "+1-8765907868"]
      try {
        let response = await apiService.AlertServices.gettingCustomMblNumbers(
          obj
        );
        if (response.status === 200) {
          this.selected_cust_mobile_nbs = [];
          if(response.data.length && 
            response.data[0] && 
            response.data[0]?.mobileNumbers && 
            response.data[0]?.mobileNumbers.length
          ){
            this.customMobileNumbers = response.data[0]?.mobileNumbers
          }
          else{
            this.customMobileNumbers = [];
          }
        }
        else{
          this.customMobileNumbers = [];
          this.selected_cust_mobile_nbs = [];
        }
      }
      catch(err){
        console.error(err)
      }  
    },
    bindingTheAlreadyHavingDataToPropertyBar(mode) {
      console.log("new--------- configs", this.emailConfig, this.smsConfig);
      let config = mode === 'email' ? this.emailConfig : this.smsConfig
      this.isCCChecked = false;
      this.isPoChecked = false;
      this.isCchecked = false;
      this.whichWidgetsAreShown = []
      if (config) {
        this.organizationHierarchy.alertMode = mode;
        for (let [key, value] of Object.entries(config)) {
          if (key.includes("pivotal")) {
            if (key.includes("duration")) {
              this.durationBwAlerts = value / 60000;
              console.log("checking value...duration", value);
            } else {
              this.alertInfo.alertFrequency = value;
              console.log("checking value...", value);
            }
            this.isPoChecked = true;
            this.whichWidgetsAreShown.push("PIVOTAL_ALERTS");
            console.log("alerts checking::::::::3");
          } else if (key.includes("critical")) {
            if (key.includes("duration")) {
              this.durationBwAlerts = value / 60000;
              console.log("checking value...duration", value);
            } else {
              this.alertInfo.alertFrequency = value;
              console.log("checking value...", value);
            }
            this.isCCChecked = true;
            this.whichWidgetsAreShown.push("CRITICAL_CONDITION_ALERTS");
            console.log("alerts checking::::::::4");
          } else if (key.includes("crucial")) {
            if (key.includes("duration")) {
              this.durationBwAlerts = value / 60000;
              console.log("checking value...duration", value);
            } else {
              this.alertInfo.alertFrequency = value;
              console.log("checking value...", value);
            }
            this.isCchecked = true;
            this.whichWidgetsAreShown.push("CRUCIAL_ALERTS");
            console.log("alerts checking::::::::5");
          } else if(key.includes("communicationrequired")) {
            this.alertInfo.communicationRequired = !value
            console.log("Alert---checked or not1", value);
          }
          else if(mode == 'sms' && key == 'customNumbers' && Object.keys(value).length){
            this.selected_cust_mobile_nbs = Object.keys(value);
          }
        }
      } else {
        // this.organizationHierarchy.alertMode = "sms";
        // for (let [key, value] of Object.entries(this.smsConfig)) {
        //   if (key.includes("pivotal")) {
        //     if (key.includes("duration")) {
        //       this.durationBwAlerts = value / 60000;
        //       console.log("checking value...duration", value);
        //     } else {
        //       this.alertInfo.alertFrequency = value;
        //       console.log("checking value...", value);
        //     }
        //     this.isPoChecked = true;
        //   } else if (key.includes("critical")) {
        //     if (key.includes("duration")) {
        //       this.durationBwAlerts = value / 60000;
        //       console.log("checking value...duration", value);
        //     } else {
        //       this.alertInfo.alertFrequency = value;
        //       console.log("checking value...", value);
        //     }
        //     this.isCCChecked = true;
        //   } else if (key.includes("crucial")) {
        //     if (key.includes("duration")) {
        //       this.durationBwAlerts = value / 60000;
        //       console.log("checking value...duration", value);
        //     } else {
        //       this.alertInfo.alertFrequency = value;
        //       console.log("checking value...", value);
        //     }
        //     this.isCchecked = true;
        //   } else {
        //     // this.alertInfo.communicationRequired = value;
        //     console.log("Alert---checked or not1", value);
        //   }
        // }
      }
      this.whichWidgetsAreShown = [...new Set(this.whichWidgetsAreShown)];
      console.log(
        "alerts checking::::::::1",
        this.whichWidgetsAreShown,
        this.emailConfig
      );
    },
    async gettingAlertConfig() {
      let dtls = this.$store.state.disp.displays[this.displayId];
      let obj = {
        wellId: dtls.wellId,
        wellBoreName: dtls.wellboreId,
        entityId: this.entityId,
      };
      try {
        let response = await apiService.AlertServices.selectedWellAlertInfo(
          obj
        );
        if (response.status === 200) {
          console.log("new---------response data", response.data);
          let configData = response.data[0].communicationConfigurationData;
          console.log("new---------response data", configData);
          for (let each of configData) {
            for (let user of each.userid) {
              if (user === this.userId) {
                if (each.communicationtype === "email") {
                  this.emailConfig = each;
                } else {
                  this.smsConfig = each;
                }
              }
            }
          }
          if(this.emailConfig){
            this.bindingTheAlreadyHavingDataToPropertyBar("email");
          }else if(this.smsConfig){
            this.bindingTheAlreadyHavingDataToPropertyBar("sms");
          }else{
            // this.alertInfo.communicationRequired = false
          }
          console.log("new---------", dtls);

          // console.log("new-------pivotal ee", this.pivotal__E);
        } else {
          this.alertInfo.communicationRequired = false
          console.error(response);
        }
      } catch (error) {
        console.error(error);
      }
    },
    gettingNewRecordKeyAndValuesBasedOnSelectionOfAlert() {
      let newRecord = {
        userid: [this.userId],
        communicationrequired: !this.alertInfo.communicationRequired,
        communicationtype: this.organizationHierarchy.alertMode,
      };
      for (let eachAlert of this.whichWidgetsAreShown) {
        let keyWord = eachAlert.split("_")[0].toLowerCase();
        let noOfMsgs = `default${keyWord}alertsmaxnumberofmessages`;
        let duration = `default${keyWord}alertsduration`;
        newRecord[noOfMsgs] = this.alertInfo.alertFrequency;
        newRecord[duration] = this.alertInfo.alertDuration;
      }
      // ADDING CUSTOM MBL NUMBERS CODE STARTS
      if(this.organizationHierarchy.alertMode == 'sms' && this.selected_cust_mobile_nbs.length){
        let numbersObj = {}
        this.selected_cust_mobile_nbs.map(each=> {
          let key = each.toString();
          numbersObj[key] = this.alertInfo.alertFrequency ? this.alertInfo.alertFrequency.toString() : this.alertInfo.alertFrequency;
        })
        newRecord['customNumbers'] = numbersObj
      }
      // ADDING CUSTOM MBL NUMBERS CODE ENDS
      return newRecord;
    },

    getingModifiedCommunicationData(
      communicationData,
      matchedOneIndex,
      matchedObj,
      indexOfAlredyHaveingRecord,
      alredyHaveingRecordBasedOnUser
    ) {
      let modifiedCommunicationConfigData = [];

      if (matchedObj && !alredyHaveingRecordBasedOnUser) {
        modifiedCommunicationConfigData = communicationData.filter(
          (each) => each !== matchedObj
        );
      } else if (alredyHaveingRecordBasedOnUser && matchedObj) {
        modifiedCommunicationConfigData = communicationData.filter(
          (each) => each !== matchedObj
        );
        modifiedCommunicationConfigData =
          modifiedCommunicationConfigData.filter(
            (each) => each !== alredyHaveingRecordBasedOnUser
          );
      } else if (alredyHaveingRecordBasedOnUser && !matchedObj) {
        modifiedCommunicationConfigData = communicationData.filter(
          (each) => each !== alredyHaveingRecordBasedOnUser
        );
      }
      return modifiedCommunicationConfigData;
    },
    toggleClicked(message) {
      console.log(
        "Alert---checked or not",
        message
      );
    },
    async onSaveClicked() {
      let dtls = this.$store.state.disp.displays[this.displayId];
      console.log("dtls>>>", dtls);
      if (dtls) {
        console.log("checking::::::::::::::1",!this.alertInfo.communicationRequired)
        if (
          this.whichWidgetsAreShown.length &&
          this.organizationHierarchy.alertMode !== null && 
          (this.alertInfo.alertFrequency || this.alertInfo.alertFrequency === 0) &&
          (this.durationBwAlerts || this.durationBwAlerts === 0)
        ) {
          console.log("Alert--->>>>>in properties", dtls);
          console.log("Alert---userId>>>>>", this.userId, this.entityId);
          let durationOfTime = this.durationBwAlerts * 60 * 1000;
          this.isSeveariyClicked
            ? (this.alertInfo.alertFrequency = 1)
            : this.alertInfo.alertFrequency;
          this.alertInfo.alertDuration = durationOfTime;
          let haveingSameRecord = false;

          console.log(`Alert---final details ${dtls.wellId}
           ,${dtls.wellboreId},
           ${this.entityId},
           ${this.userId},
           ${this.organizationHierarchy.alertMode},
           ${this.alertInfo.alertPicker}
           ${(this.alertSeverity = this.whichWidgetsAreShown)}
           ${!this.alertInfo.communicationRequired}`);
          // this.toDate = "";
          // this.formDate = "";
          // this.organizationHierarchy= {
          //   displayName: null,
          //   jobRole: null,
          //   apolloDartSolutionType: null,
          //   alertMode: null,
          // }
          // this.alertInfo = {
          //   alertPicker: null,
          //   alertFrequency: null,
          //   alertDuration: null,
          //   communicationRequired: true,
          //   communicationtype: null,
          // }
          let obj = {
            wellId: dtls.wellId,
            wellBoreName: dtls.wellboreId,
            entityId: this.entityId,
          };

          let response = await apiService.AlertServices.selectedWellAlertInfo(
            obj
          );

          console.log(
            "Alert---searching whether well has data or not",
            response
          );
          let alredyHaveingRecordBasedOnUserArray = [];
          let alredyHaveingRecordBasedOnUser,
            indexOfAlredyHaveingRecord,
            newRecord,
            anoterOneRecord,
            isAnotherUserThere,
            communicationConfigId,
            alredyHaveingUserCommunicationType,
            wellInfoObj;
          let isCommunicationTypesame = null;
          //   CHECKING WHETHER THE WELL HAS A RECORED OR NOT
          if (response.status === 204) {
            newRecord = {
              userid: [this.userId],
              communicationrequired: !this.alertInfo.communicationRequired,
              communicationtype: this.organizationHierarchy.alertMode,
            };
            // ADDING CUSTOM NUMBERS CODE STARTS
            if(newRecord?.communicationtype == 'sms' && this.selected_cust_mobile_nbs.length){
              let numbersObj = {}
              this.selected_cust_mobile_nbs.map(each=> {
                let key = each.toString();
                numbersObj[key] = this.alertInfo.alertFrequency ? this.alertInfo.alertFrequency.toString() : this.alertInfo.alertFrequency;
              })
              newRecord['customNumbers'] = numbersObj
            }
            // ADDING CUSTOM NUMBERS CODE ENDS
            console.log("Alert--- well has no data", this.whichWidgetsAreShown);
            for (let eachAlert of this.whichWidgetsAreShown) {
              let keyWord = eachAlert.split("_")[0].toLowerCase();

              let noOfMsgs = `default${keyWord}alertsmaxnumberofmessages`;
              let duration = `default${keyWord}alertsduration`;
              newRecord[noOfMsgs] = this.alertInfo.alertFrequency;
              newRecord[duration] = this.alertInfo.alertDuration;
            }

            let postObj = {
              ...obj,
              communicationConfigurationData: [newRecord],
            };
            let response = await apiService.AlertServices.saveTheWellAlertInfo(
              postObj
            );
            // console.log("Alert--- well has no data after posting newData",response);
            if (response.status === 200) {
              this.$toast.success("Changes Updated", {
                position: "top",
                duration: "2000",
              });
            }
            console.log("Alert---well has no data", postObj, response);
          } else {
            wellInfoObj = { ...response.data[0] };
            //  WELL HAS A RECORD
            communicationConfigId = wellInfoObj.communicationConfigId;
            //  CHECKING USER HAS A RECORED OR NOT communicationConfigurationData
            console.log(
              "Alert---communicationConfigData1",
              wellInfoObj.communicationConfigurationData
            );
            let toKnowUserHasData = [
              ...wellInfoObj.communicationConfigurationData,
            ];

            for (let eachObj of toKnowUserHasData) {
              let userIds = eachObj.userid;
              for (let id of userIds) {
                if (id === this.userId) {
                  console.log(
                    "Alert---**************enter-----",
                    eachObj.communicationtype
                  );
                  // indexOfAlredyHaveingRecord = toKnowUserHasData.indexOf(eachObj);
                  alredyHaveingRecordBasedOnUserArray.push(eachObj);
                }
              }
            }

            for (let eachObj of alredyHaveingRecordBasedOnUserArray) {
              if (
                eachObj.communicationtype ===
                this.organizationHierarchy.alertMode
              ) {
                indexOfAlredyHaveingRecord = toKnowUserHasData.indexOf(eachObj);
                alredyHaveingRecordBasedOnUser = eachObj;
              }
            }

            console.log(
              "Alert--- exited user or not",
              indexOfAlredyHaveingRecord,
              isCommunicationTypesame
            );
            // IF USER HAS A RECORD
            if (alredyHaveingRecordBasedOnUser) {
              alredyHaveingUserCommunicationType =
                alredyHaveingRecordBasedOnUser.communicationtype;
              isCommunicationTypesame =
                alredyHaveingUserCommunicationType ===
                this.organizationHierarchy.alertMode;
              // HERE I CHECKED FOR ANOTHER USER IS THERE OR NOT
              isAnotherUserThere =
                alredyHaveingRecordBasedOnUser.userid.length > 1;
              // here i have to check whether the communication type is same or not if not same then i have to create new record if it is same then check of all
              if (isCommunicationTypesame) {
                console.log("Alert---communicationtype is same");
                let objOfknowing = {};
                // CHECKING WETHER THE DATA IS SMAE OR NOT
                // KNOWING WHICH KEYS EXISTING RECORD HAS WHICH RECORD HAS NOT THERE WHICH KEY'S VALUE IS DIFFRENT

                for (let eachAlert of this.whichWidgetsAreShown) {
                  let keyWord = eachAlert.split("_")[0].toLowerCase();
                  let noOfMsgs = `default${keyWord}alertsmaxnumberofmessages`;
                  let duration = `default${keyWord}alertsduration`;
                  if (
                    Object.keys(alredyHaveingRecordBasedOnUser).includes(
                      noOfMsgs
                    )
                  ) {
                    let isMatchedNoOfMsgs =
                      alredyHaveingRecordBasedOnUser[noOfMsgs] ===
                      this.alertInfo.alertFrequency;
                    let isMatchedDuration =
                      alredyHaveingRecordBasedOnUser[duration] ===
                      this.alertInfo.alertDuration;
                    if (isMatchedNoOfMsgs && isMatchedDuration) {
                      // haveing same  key and value
                      // check communication required or not  if it is diffrent then check any other is there or not
                      // communication required is also same no need to post
                      if (
                        alredyHaveingRecordBasedOnUser.communicationrequired ===
                        !this.alertInfo.communicationRequired
                      ) {
                        objOfknowing[eachAlert] = "sameKeyAndValue";
                      } else {
                        objOfknowing[eachAlert] = "sameKeyAndDiffValue";
                      }
                    } else {
                      //  haveing key but value is diffrent
                      objOfknowing[eachAlert] = "sameKeyAndDiffValue";
                    }
                  } else {
                    // key is not there
                    objOfknowing[eachAlert] = "noKeyAndnoValue";
                  }
                }

                // CHECKING IF HAD MOBILE NUMBERS OR NOT CODE STARTS
                if(this.organizationHierarchy.alertMode == 'sms' && this.selected_cust_mobile_nbs.length){
                  if(alredyHaveingRecordBasedOnUser?.customNumbers && Object.keys(alredyHaveingRecordBasedOnUser?.customNumbers)?.length){
                    if(JSON.stringify(Object.keys(alredyHaveingRecordBasedOnUser?.customNumbers).sort()) ==
                      JSON.stringify(this.selected_cust_mobile_nbs.sort()) 
                    ){
                      objOfknowing['customNumbers'] = "sameKeyAndValue";
                    }
                    else{
                      objOfknowing['customNumbers'] = "sameKeyAndDiffValue";
                    }
                  }
                  else{
                    objOfknowing['customNumbers'] = "noKeyAndnoValue"
                  }
                }
                // CHECKING IF HAD MOBILE NUMBERS OR NOT CODE ENDS

                let knowingValues = Object.values(objOfknowing);
                console.log(
                  "Alert--- enter to loop selected specific",
                  knowingValues
                );
                if (
                  knowingValues.includes("sameKeyAndDiffValue") ||
                  knowingValues.includes("noKeyAndnoValue")
                ) {
                  if (isAnotherUserThere) {
                    let updatedUserIdArray =
                      alredyHaveingRecordBasedOnUser.userid.filter(
                        (each) => each !== this.userId
                      );
                    anoterOneRecord = {
                      ...alredyHaveingRecordBasedOnUser,
                      userid: updatedUserIdArray,
                    };
                    //  mian change 30/03/2023 for not removeing previos ones
                    newRecord = {
                      ...alredyHaveingRecordBasedOnUser,
                      userid: [this.userId],
                      communicationrequired:
                        !this.alertInfo.communicationRequired,
                      communicationtype: this.organizationHierarchy.alertMode,
                    };
                    for (let eachAlert of this.whichWidgetsAreShown) {
                      let keyWord = eachAlert.split("_")[0].toLowerCase();
                      let noOfMsgs = `default${keyWord}alertsmaxnumberofmessages`;
                      let duration = `default${keyWord}alertsduration`;
                      newRecord[noOfMsgs] = this.alertInfo.alertFrequency;
                      newRecord[duration] = this.alertInfo.alertDuration;
                    }
                    // ADDING CUSTOM MBL NUMBERS CODE STARTS
                    if(this.organizationHierarchy.alertMode == 'sms' && this.selected_cust_mobile_nbs.length){
                      let numbersObj = {}
                      this.selected_cust_mobile_nbs.map(each=> {
                        let key = each.toString();
                        numbersObj[key] = this.alertInfo.alertFrequency ? this.alertInfo.alertFrequency.toString() : this.alertInfo.alertFrequency;
                      })
                      newRecord['customNumbers'] = numbersObj
                    }
                    // ADDING CUSTOM MBL NUMBERS CODE ENDS
                  } else {
                    console.log("Alert---communicationtype is diffrent");
                    //  mian change 30/03/2023 for not removeing previos ones
                    newRecord = {
                      ...alredyHaveingRecordBasedOnUser,
                      userid: [this.userId],
                      communicationrequired:
                        !this.alertInfo.communicationRequired,
                      communicationtype: this.organizationHierarchy.alertMode,
                    };
                    for (let eachAlert of this.whichWidgetsAreShown) {
                      let keyWord = eachAlert.split("_")[0].toLowerCase();
                      let noOfMsgs = `default${keyWord}alertsmaxnumberofmessages`;
                      let duration = `default${keyWord}alertsduration`;
                      newRecord[noOfMsgs] = this.alertInfo.alertFrequency;
                      newRecord[duration] = this.alertInfo.alertDuration;
                    }
                    // ADDING CUSTOM MBL NUMBERS CODE STARTS
                    if(this.organizationHierarchy.alertMode == 'sms' && this.selected_cust_mobile_nbs.length){
                      let numbersObj = {}
                      this.selected_cust_mobile_nbs.map(each=> {
                        let key = each.toString();
                        numbersObj[key] = this.alertInfo.alertFrequency ? this.alertInfo.alertFrequency.toString() : this.alertInfo.alertFrequency;
                      })
                      newRecord['customNumbers'] = numbersObj
                    }
                    // ADDING CUSTOM MBL NUMBERS CODE ENDS
                  }
                } else {
                  // check all keys in existed obj are there in new obj
                  //  here why i am not checking values previousely i checked for values
                  console.log("Alert---communicationtype is diffrent");
                  newRecord = {
                    userid: [this.userId],
                    communicationrequired: !this.alertInfo.communicationRequired,
                    communicationtype: this.organizationHierarchy.alertMode,
                  };
                  for (let eachAlert of this.whichWidgetsAreShown) {
                    let keyWord = eachAlert.split("_")[0].toLowerCase();
                    let noOfMsgs = `default${keyWord}alertsmaxnumberofmessages`;
                    let duration = `default${keyWord}alertsduration`;
                    newRecord[noOfMsgs] = this.alertInfo.alertFrequency;
                    newRecord[duration] = this.alertInfo.alertDuration;
                  }

                  // ADDING CUSTOM MBL NUMBERS CODE STARTS
                  if(this.organizationHierarchy.alertMode == 'sms' && this.selected_cust_mobile_nbs.length){
                    let numbersObj = {}
                    this.selected_cust_mobile_nbs.map(each=> {
                      let key = each.toString();
                      numbersObj[key] = this.alertInfo.alertFrequency ? this.alertInfo.alertFrequency.toString() : this.alertInfo.alertFrequency;
                    })
                    newRecord['customNumbers'] = numbersObj
                  }
                  // ADDING CUSTOM MBL NUMBERS CODE ENDS

                  let areAllKeysThere =
                    Object.keys(newRecord).length ===
                    Object.keys(alredyHaveingRecordBasedOnUser).length;
                  if (areAllKeysThere) {
                    haveingSameRecord = true;
                  }
                  else{
                    if (isAnotherUserThere) {
                      let updatedUserIdArray =
                        alredyHaveingRecordBasedOnUser.userid.filter(
                          (each) => each !== this.userId
                        );
                      anoterOneRecord = {
                        ...alredyHaveingRecordBasedOnUser,
                        userid: updatedUserIdArray,
                      };
                    }
                  }
                }
                console.log(
                  "Alert---user has record communicationType same block NR,AR haveingSameRecord",
                  newRecord,
                  anoterOneRecord,
                  haveingSameRecord
                );
              } else {
                // create new record based on new values because communicationtype is diffrent
                // COMMUNICATION TYPE IS DIFFRENT WE DONT NEED ANY CHECKINGS DIRECTLY CREATE A NEW RECORD
                newRecord =
                  this.gettingNewRecordKeyAndValuesBasedOnSelectionOfAlert();
              }
            } else {
              // IF USER DOESN'T HAS A RECORD
              newRecord =
                this.gettingNewRecordKeyAndValuesBasedOnSelectionOfAlert();
              console.log("Alert---user has no record block NR,AR", newRecord);
              anoterOneRecord;
            }
            // CHECK NEW RECORD IS MATCHED WITH ANY OTHER USER RECORD
            // IF IT IS MATCHED THEN MERGE THIS NEW RECORD TO MATCHED ONE THEN AFTER POST THE MERGED RECORD
            // IF IT IS NOT MATCHED POST THE NEW RECORD
            console.log(
              "Alert---Before modified communicationConfigData",
              toKnowUserHasData
            );
            let testObjForNewRecord = { ...newRecord };
            let newRecordCheck = { ...testObjForNewRecord, userid: "" };
            let matchedOneIndex, matchedObj, mergedObj;
            console.log("Alert---another one record", anoterOneRecord);
            let communicationConfigurationDataCopy = [...toKnowUserHasData];
            for (let eachObj of communicationConfigurationDataCopy) {
              let checkingObj = { ...eachObj, userid: "" };

              let isKeysAreSame =
                JSON.stringify(Object.keys(newRecordCheck).sort()) ===
                JSON.stringify(Object.keys(checkingObj).sort());
              let isValuesAreSame =
                JSON.stringify(Object.values(newRecordCheck).sort()) ===
                JSON.stringify(Object.values(checkingObj).sort());
              console.log(
                "Alert---keysofnewRecord",
                JSON.stringify(Object.keys(newRecordCheck).sort()) ===
                  JSON.stringify(Object.keys(checkingObj).sort())
              );
              if (isKeysAreSame && isValuesAreSame) {
                console.log(
                  "Alert---finally enter into loop for checking matched obj"
                );
                matchedOneIndex =
                  communicationConfigurationDataCopy.indexOf(eachObj);
                matchedObj = eachObj;
              }
            }
            console.log(
              "Alert---matched obj and new record",
              matchedObj,
              newRecord,
              anoterOneRecord
            );
            if (matchedObj) {
              let mergedUsers = [...matchedObj.userid, ...newRecord.userid];
              mergedUsers = [...new Set(mergedUsers)];
              mergedObj = { ...matchedObj, userid: mergedUsers };
              newRecord = mergedObj;
              console.log("Alert-----mergedddddddd", newRecord);
            }
            let communicationData = [
              ...wellInfoObj.communicationConfigurationData,
            ];
            let modifiedCommunicationConfigData =
              isCommunicationTypesame ||
              matchedObj ||
              alredyHaveingRecordBasedOnUser
                ? this.getingModifiedCommunicationData(
                    communicationData,
                    matchedOneIndex,
                    matchedObj,
                    indexOfAlredyHaveingRecord,
                    alredyHaveingRecordBasedOnUser
                  )
                : communicationData;
            if (alredyHaveingRecordBasedOnUser) {
              if (isCommunicationTypesame || matchedObj) {
                modifiedCommunicationConfigData =
                  this.getingModifiedCommunicationData(
                    communicationData,
                    matchedOneIndex,
                    matchedObj,
                    indexOfAlredyHaveingRecord,
                    alredyHaveingRecordBasedOnUser
                  );
              } else {
                modifiedCommunicationConfigData = communicationData;
              }
            } else {
              if (matchedObj) {
                this.getingModifiedCommunicationData(
                  communicationData,
                  matchedOneIndex,
                  matchedObj,
                  indexOfAlredyHaveingRecord,
                  alredyHaveingRecordBasedOnUser
                );
              } else {
                console.log("matched object is there or not in else");
                modifiedCommunicationConfigData = communicationData;
              }
            }
            console.log(
              "Alert---After modified communicationConfigData",
              matchedOneIndex,
              indexOfAlredyHaveingRecord,
              modifiedCommunicationConfigData,
              communicationData
            );
            if (isAnotherUserThere) {
              if (newRecord && anoterOneRecord) {
                console.log("Alert---11");
                modifiedCommunicationConfigData = [
                  ...modifiedCommunicationConfigData,
                  newRecord,
                  anoterOneRecord,
                ];
              }
              //  even another user is there another one record is not coming because communication type diffrence
              // so we need this case of checking
              else if (newRecord) {
                console.log("Alert---12");
                modifiedCommunicationConfigData = [
                  ...modifiedCommunicationConfigData,
                  newRecord,
                ];
              } else if (anoterOneRecord) {
                console.log("Alert---13");
                modifiedCommunicationConfigData = [
                  ...modifiedCommunicationConfigData,
                  anoterOneRecord,
                ];
              }
            } else {
              console.log("Alert---14", modifiedCommunicationConfigData);
              modifiedCommunicationConfigData = [
                ...modifiedCommunicationConfigData,
                newRecord,
              ];
            }
            let postObj = {
              ...obj,
              communicationConfigurationData: modifiedCommunicationConfigData,
              communicationConfigId: communicationConfigId,
            };

            if (!haveingSameRecord) {
              // POSTING AREA START
              let response = await apiService.AlertServices.saveTheWellAlertInfo(
                postObj
              );
              console.log(
                "Alert--- well has  data after posting newData",
                response
              );
              if (response.status === 200) {
                this.$toast.success("Changes Updated", {
                  position: "top",
                  duration: "2000",
                });
              } else {
                this.$toast.error("SOMETHING WENT WRONG CHANGES ARE NOT UPDATED", {
                  position: "top",
                  duration: "2000",
                });
              }
              //   //  POSTING AREA END
            } else {
              this.$toast.error("YOU DIDN'T DO ANY CHANGES", {
                position: "top",
                duration: "2000",
              });
            }

            console.log(
              "checking data before posting 1",
              newRecord,
              anoterOneRecord,
              postObj
            );
          }
        } else {
          if (!this.organizationHierarchy.alertMode) {
            this.$toast.clear();
            this.$toast.error("Please Select Alert Mode", {
              position: "top",
              duration: "2000",
            });
          } 
          else if(!this.alertInfo.alertFrequency){
            this.$toast.clear();
            this.$toast.error("Please Enter Alert Frequency", {
              position: "top",
              duration: "2000",
            });
          }
          else if(!this.durationBwAlerts){
            this.$toast.clear();
            this.$toast.error("Please Enter Duration", {
              position: "top",
              duration: "2000",
            });
          }
          else {
            this.$toast.clear();
            this.$toast.error("Please Select Alert", {
              position: "top",
              duration: "2000",
            });
          }
        }
      } else {
          console.log("checking::::::::::::::3",this.alertInfo.communicationRequired)
          this.$toast.error("PLEASE SELECT WELL", {
          position: "top",
          duration: "2000",
        });
      }
    },
    onAlertSeverityClicked(data) {
      this.onAlertPickerClicked(data);
      this.isSeveariyClicked = true;
    },
    onAlertPickerClicked(data) {
      console.log("all clicked", this.isAllChekced);
      if (data !== "all") {
        if (this.whichWidgetsAreShown.includes(data)) {
          let index = this.whichWidgetsAreShown.indexOf(data);
          this.whichWidgetsAreShown.splice(index, 1);
        } else {
          this.whichWidgetsAreShown.push(data);
        }
      } else {
        if(this.whichWidgetsAreShown.length){
          this.whichWidgetsAreShown = []
        }else{
          this.whichWidgetsAreShown = [
          "PIVOTAL_ALERTS",
          "CRUCIAL_ALERTS",
          "CRITICAL_ALERTS",
        ];
        }
        
      }
      this.isSeveariyClicked = false;
      console.log("alerts checking::::::::2", this.whichWidgetsAreShown);
    },
    async wellNameChanged() {
      console.log("geting res", this.selectedWell, this.selectedWellBore);
    },
    onSelectOfrightBarClicked(selectNum) {
      let index = this.rightBarSelectclicked.indexOf(selectNum);
      if (index !== -1) {
        this.rightBarSelectclicked.splice(index, selectNum);
      } else {
        this.rightBarSelectclicked.push(selectNum);
      }
    },
    widgetclicked() {
      console.log("double clicked");
      this.isWidgetDbclicked = !this.isWidgetDbclicked;
    },
    onSelectingTheAlertType(tabNum) {
      let isExistedTab = tabNum in this.storingObj;
      console.log("alerts clicking", isExistedTab);
      if (tabNum === "CRITICAL_CONDITION_ALERTS") {
        if (isExistedTab) {
          delete this.storingObj[tabNum];
        } else {
          this.storingObj[tabNum] = this.criticalAlerts;
          console.log("alerts pivotalExicuting", this.storingObj);
        }
      } else if (tabNum === "CRUCIAL_ALERTS") {
        if (isExistedTab) {
          delete this.storingObj[tabNum];
        } else {
          this.storingObj[tabNum] = this.crucialAlerts;
        }
      } else if (tabNum === "PIVOTAL_ALERTS") {
        if (isExistedTab) {
          delete this.storingObj[tabNum];
        } else {
          console.log("alerts entered");
          this.storingObj[tabNum] = [...this.pivotalAlerts];
        }
      } else if (tabNum === "all") {
        this.alerts = [
          ...this.pivotalAlerts,
          this.crucialAlerts,
          this.criticalAlerts,
        ];
      }
      let arrayForStoring = [];
      Object.keys(this.storingObj).map((each) => {
        arrayForStoring = [...arrayForStoring, ...this.storingObj[each]];
        console.log("alerts>>>>>>>", this.storingObj[each]);
      });
      this.alerts = arrayForStoring;
      console.log("alerts>>>", arrayForStoring);
    },
  },
  async mounted() {
    let details = this.$serviceHelpers.getDetails("apollodart");
    console.log("new---------details apollodart", details);
    this.userId = details.user.user_id;
    let jobRole = details.user.user_roles.role_name;
    let userName = details.user.user_name;
    this.entityId = details.user.entity_id;
    this.organizationHierarchy["displayName"] = userName;
    this.organizationHierarchy["jobRole"] = jobRole;
    await this.getting_wellwise_custom_mbl_numbers();
    this.gettingAlertConfig();
  },
};
</script>

<style>
.AlertTimeHeading {
  /* font-size: 13px; */
  padding: 6px;
}

.dp__input {
  outline: none;
  width: 66%;
  font-size: min(0.9vw, 12px);
  font-weight: 600;
  line-height: 0.1rem;
  padding: 6px 0 6px 6px;
  /* color: #ffffff; */
  border: 0.5px solid #898989;
  text-align: center;
  height: 20px;
  background: var(--colorBoxBg);
  color: var(--textColor);
  border: none;
}

.saveBtn {
  font-size: 11px;
  background-color: var(--activeTextColor);
  padding: 2px 16px;
  color: #fff;
}

.switch {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 11px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 9px;
  width: 9px;
  left: 3px;
  /* bottom: 4px; */
  top: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(11px);
  -ms-transform: translateX(11px);
  transform: translateX(11px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.bodyOfrightSidebar {
  height: 77vh;
}

.alertPickerHeading {
  padding: 4px 6px;
}

.settingsHeading {
  color: var(--headingColor);
}

.rotate-up {
  transform: rotate(180deg);
}
</style>
