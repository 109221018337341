<template>
    <div class="relative w-full mt-1">
      <div
        class="selectedOptions flex"
        :class="{ activeBorders: showList }"
        @click="openListDiv"
      >
        <div
          v-for="(item, index) in selectedOptions"
          :key="index"
          class="flex m-1 items-center selectedOption"
        >
          <li class="list-none mr-2 text-xs">{{ item }}</li>
          <button
            type="button"
            @click.stop="this.$emit('selectOption', item)"
            class="cursor-pointer fontSize"
          >
            <i class="fas fa-times cursor-pointer"></i>
          </button>
        </div>
      </div>
      <div
        class="listDiv"
        :class="{ activeBordersOfList: showList }"
        v-show="showList"
      >
        <li
          class="list-none listItem text-sm select-none flex items-center"
          v-for="item in reqOptions"
          :key="item"
        >
          <input
            :checked="
              selectedOptions.length
                ? selectedOptions.some(
                    (each) => each == item
                  )
                : false
            "
            @change="this.$emit('selectOption', item)"
            type="checkbox"
            class="cursor-pointer mr-2"
            :id="item"
          />
          <label
            class="w-full inline-block cursor-pointer"
            :for="item"
            >{{ item }}</label
          >
        </li>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        @click="openListDiv"
        width="10"
        height="10"
        class="downArrowOfSelect cursor-pointer"
        :class="{ activeDown: showList }"
        viewBox="0 0 384 512"
      >
        <path
          fill="white"
          d="M169.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 274.7 54.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
        />
      </svg>
    </div>
  </template>
   
   <script>
  export default {
    emits: ["selectOption"],
    data() {
      return {
        showList: false,
      };
    },
    props: {
      reqOptions: {
        type: Array,
        default: [],
      },
      selectedOptions: {
        type: Array,
        default: [],
      },
    },
    methods: {
      openListDiv() {
        this.showList = !this.showList;
      },
    },
  };
  </script>
   
   <style scoped>
  .activeBordersOfList {
    border: 1px solid var(--activeTextColor);
    border-top: none;
  }
  .activeBorders {
    border-top: 1px solid var(--activeTextColor) !important;
    border-right: 1px solid var(--activeTextColor) !important;
    border-left: 1px solid var(--activeTextColor) !important   ;
  }
  .selectedOption {
    background-color: #b4b5ba15;
    /* padding:1px;  */
    font-size: 10px;
    padding-left: 6px;
    padding-right: 6px;
    height: 22px;
  }
  .selectedOption li {
    color: var(--sidebarListColor);
  }
  .selectedOptions {
    /* border: none; */
    border-radius: 0px;
    left: 0;
    /* height: 50px; */
    min-height: 30px;
    max-height: 30px;
    font-size: 13px;
    padding: 0px 0px 0px 2px;
    overflow-y: auto;
    flex-wrap: wrap;
    appearance: auto;
    /* background: var(--searchBarBg); */
    /* color: var(--searchColor); */
    /* background: var(--sidebarListBgColor); */
    background-color: var(--rightBarSelecteleBg);
    color: var(--textColor);
  }
  .listItem {
    padding: 1px 8px;
    font-size: calc(0.3vw + 0.3vh + 0.35vmin);
    color: var(--sidebarListColor);
  }
  .listItem:hover {
    background-color: #b4b5ba15;
    border-bottom: 1px solid var(--activeTextColor);
  }
  .activeDown {
    transform: rotate(-180deg);
  }
  .activeDown path {
    fill: var(--activeTextColor);
  }
  .listDiv {
    min-height: 50px;
    max-height: 150px;
    overflow-y: auto;
    padding-top: 10px;
    /* background: var(--searchBarBg); */
    /* background: var(--sidebarListBgColor); */
    background-color: var(--rightBarSelecteleBg);
  }
  .downArrowOfSelect {
    position: absolute;
    top: 10px;
    right: 5px;
    margin-left: 5px;
  }
  .downArrowOfSelect path {
    fill: var(--textColor);
  }
  .fontSize {
    font-size: 10px;
  }
  </style>